import React, { Component } from 'react'
import { Link } from 'gatsby'

class CardProject extends Component {
    render() {
        const { data } = this.props
        const photo = data.featured_media.localFile.childImageSharp.resize.src

        return (
            <Link className="card__link" to={`/work/${data.slug}`}>
                <div className="card__wrapper">
                    <div className="card__hover"></div>
                    <img className="card__image" src={photo} alt={data.title} />
                    <div className="card__body">
                        <h4 className="card__title">{data.title}</h4>
                        <span></span>
                    </div>
                </div>
            </Link>
        )
    }
}


// TODO: Need a fallback when there isn't a featured image || make it required in WP

// CardProject.defaultProps = {
//     photo: 'http://s3.amazonaws.com/37assets/svn/765-default-avatar.png'
// }

export default CardProject