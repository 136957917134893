import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CardProject from '../components/card-project'

export default class Work extends Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO 
          title="Work" 
          keywords={[`gatsby`, `application`, `react`]} />
        <h1 class="page__title">My Work</h1>
        <p>Over the last 15 years or so, I’ve been fortunate to work on some really amazing projects. Due to the nature of the businesses I work with, I’m not always able to show some of my best work via this website.</p>
        <ul className="blog-roll__list">
          {data.allWordpressWpProject.edges.map(item => (
            <li className="blog-roll__list-item" key={item.node.wordpress_id}>
              <CardProject data={item.node} />
            </li>
          ))}
        </ul>
      </Layout>
    )
  }
}

export const query = graphql`
  query ProjectsQuery {
    allWordpressWpProject {
      edges {
        node {
          wordpress_id
          type
          slug
          title
          acf {
            project_category {
              name
            }
          }
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                resize(width: 350, height: 320, cropFocus: CENTER ) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`